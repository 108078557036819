.flatpickr-calendar .flatpickr-day {
  color: #6e6b7b; }

.flatpickr-calendar .flatpickr-day.today {
  border-color: #fd8204; }

.flatpickr-calendar .flatpickr-day.today:hover {
  background: transparent;
  color: #6e6b7b; }

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: #fd8204;
  color: #fff;
  border-color: #fd8204; }

.flatpickr-calendar .flatpickr-day.inRange, .flatpickr-calendar .flatpickr-day.inRange:hover {
  background: #f3f2fe;
  border-color: #f3f2fe;
  box-shadow: -5px 0 0 #f3f2fe, 5px 0 0 #f3f2fe; }

.flatpickr-calendar .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-day.endRange, .flatpickr-calendar .flatpickr-day.startRange:hover, .flatpickr-calendar .flatpickr-day.endRange:hover {
  background: #fd8204;
  color: #fff;
  border-color: #fd8204; }

.flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #fd8204; }

.flatpickr-calendar .flatpickr-day.flatpickr-disabled, .flatpickr-calendar .flatpickr-day.prevMonthDay, .flatpickr-calendar .flatpickr-day.nextMonthDay {
  color: #dae1e7; }

.flatpickr-calendar .flatpickr-day:hover {
  background: #f6f6f6; }

.flatpickr-calendar:after, .flatpickr-calendar:before {
  display: none; }

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  top: -5px; }

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover i,
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover i,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #fd8204; }

.flatpickr-calendar .flatpickr-current-month span.cur-month {
  font-weight: 300; }

.flatpickr-time {
  height: 2.714rem; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #fff; }

.dark-layout .flatpickr-calendar {
  background: #161d31;
  border-color: #161d31;
  box-shadow: none; }

.dark-layout .flatpickr-calendar .flatpickr-months i,
.dark-layout .flatpickr-calendar .flatpickr-months svg {
  fill: #b4b7bd; }

.dark-layout .flatpickr-calendar .flatpickr-month {
  color: #b4b7bd; }

.dark-layout .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 #3b4253; }

.dark-layout .flatpickr-calendar .flatpickr-weekday {
  color: #b4b7bd; }

.dark-layout .flatpickr-calendar .flatpickr-day, .dark-layout .flatpickr-calendar .flatpickr-day.today:hover {
  color: #b4b7bd; }

.dark-layout .flatpickr-calendar .flatpickr-day.selected {
  color: #fff; }

.dark-layout .flatpickr-calendar .flatpickr-day.prevMonthDay, .dark-layout .flatpickr-calendar .flatpickr-day.nextMonthDay, .dark-layout .flatpickr-calendar .flatpickr-day.flatpickr-disabled {
  color: #4e5154 !important; }

.dark-layout .flatpickr-calendar .flatpickr-day.inRange, .dark-layout .flatpickr-calendar .flatpickr-day.inRange:hover {
  background: #283046;
  border-color: #283046;
  box-shadow: -5px 0 0 #283046, 5px 0 0 #283046; }

.dark-layout .flatpickr-calendar .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  color: #b4b7bd;
  border-color: #283046; }

.dark-layout .flatpickr-calendar .flatpickr-days .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #283046; }

.dark-layout .flatpickr-calendar .flatpickr-time {
  border-color: #161d31 !important; }

.dark-layout .flatpickr-calendar .flatpickr-time .numInput,
.dark-layout .flatpickr-calendar .flatpickr-time .flatpickr-am-pm {
  color: #b4b7bd; }

.dark-layout .flatpickr-calendar .flatpickr-time .numInput:hover,
.dark-layout .flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover {
  background: #161d31; }

.dark-layout .flatpickr-calendar .flatpickr-time .arrowUp:after {
  border-bottom-color: #b4b7bd; }

.dark-layout .flatpickr-calendar .flatpickr-time .arrowDown:after {
  border-top-color: #b4b7bd; }

.dark-layout .flatpickr-time input:hover,
.dark-layout .flatpickr-time .flatpickr-am-pm:hover,
.dark-layout .flatpickr-time input:focus,
.dark-layout .flatpickr-time .flatpickr-am-pm:focus {
  background: #161d31; }

.flatpickr-input[readonly],
.flatpickr-input ~ .form-control[readonly],
.flatpickr-human-friendly[readonly] {
  background-color: inherit;
  opacity: 1 !important; }

.flatpickr-weekdays {
  margin-top: 8px; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month .numInputWrapper {
  font-size: 1.1rem;
  border-radius: 4px;
  padding: 2px;
  transition: all 0.15s ease-out; }

.flatpickr-current-month .flatpickr-monthDropdown-months span,
.flatpickr-current-month .numInputWrapper span {
  display: none; }
