.swal2-container .swal2-popup {
  border-radius: 0.428rem;
  padding-bottom: 1.5rem; }

.swal2-container .swal2-popup .swal2-close:focus {
  outline: none;
  box-shadow: none; }

.swal2-container .swal2-popup .swal2-content {
  color: #6e6b7b; }

.swal2-container .swal2-popup .swal2-title {
  font-size: 1.5rem;
  font-weight: inherit;
  color: #5e5873; }

.swal2-container .swal2-popup button.swal2-confirm:focus {
  box-shadow: none; }

.swal2-container .swal2-popup input.swal2-input {
  height: 2.714rem;
  font-size: 1rem;
  padding: 0.571rem 1rem;
  color: #6e6b7b;
  border-color: #d8d6de;
  border-radius: 0.357rem;
  line-height: 1.45; }

.swal2-container .swal2-popup input.swal2-input:focus {
  border-color: #fd8204 !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1); }

.swal2-container .swal2-popup .swal2-progress-steps .swal2-progress-step {
  background-color: #fd8204; }

.swal2-container .swal2-popup .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line,
.swal2-container .swal2-popup .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background-color: #887ef2; }

.swal2-container .swal2-popup .swal2-progress-steps .swal2-progress-step-line {
  background-color: #fd8204; }

.swal2-container .swal2-popup pre {
  min-height: 1.35rem;
  margin-top: 0.5rem; }

.swal2-container .swal2-popup .swal2-footer {
  border-color: #ebe9f1;
  color: #6e6b7b; }

.dark-layout .swal2-container .swal2-popup .swal2-title {
  color: #d0d2d6; }

.dark-layout .swal2-container .swal2-modal {
  background-color: #283046; }

.dark-layout .swal2-container .swal2-modal .swal2-header .swal2-title {
  color: #d0d2d6; }

.dark-layout .swal2-container .swal2-modal .swal2-header .swal2-icon-text {
  color: inherit; }

.dark-layout .swal2-container .swal2-modal .swal2-header .swal2-success-circular-line-left,
.dark-layout .swal2-container .swal2-modal .swal2-header .swal2-success-circular-line-right,
.dark-layout .swal2-container .swal2-modal .swal2-header .swal2-success-fix,
.dark-layout .swal2-container .swal2-modal .swal2-header .swal2-animate-success-icon {
  background-color: #283046 !important; }

.dark-layout .swal2-container .swal2-modal input.swal2-input {
  border-color: #3b4253;
  color: #b4b7bd; }

.dark-layout .swal2-container .swal2-modal .swal2-content,
.dark-layout .swal2-container .swal2-modal .swal2-html-container {
  color: #b4b7bd; }

.dark-layout .swal2-container .swal2-modal pre,
.dark-layout .swal2-container .swal2-modal code {
  color: #b4b7bd; }

.dark-layout .swal2-container .swal2-modal .swal2-input {
  background-color: #283046; }

.dark-layout .swal2-container .swal2-modal .swal2-footer {
  border-color: #3b4253;
  color: #b4b7bd; }

.dark-layout .swal2-container .swal2-validation-message {
  background-color: #161d31;
  color: #b4b7bd; }
