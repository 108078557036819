/* You can add global styles to this file, and also import other style files */

input:-webkit-autofill:disabled {
  box-shadow: 0 0 0 1000px #efefef inset !important;
  -webkit-box-shadow: 0 0 0 1000px #efefef inset !important;
  transition: background-color 0s ease-in-out 0s;
}

input::placeholder {
  text-align: start;
}

.is-invalid {
  + .select2-container {
    .select2-selection--multiple {
      border-color: #ea5455 !important;
    }
  }
}

.flatpickr-input ~ .form-control:disabled {
  background-color: #efefef !important;
  opacity: 1 !important;
}

.select2-search__field {
  &::-webkit-input-placeholder,
  &::placeholder {
    color: #b4b7bd !important;
  }
}

.select2-flag-item {
  display: flex;
  align-items: center;

  .flag-icon {
    height: 2rem !important;
    width: 2rem !important;
    border-radius: 50%;
    background-size: cover;
  }
}

.badge-tag-ct01 {
  color: rgba(234, 84, 85, 1);
  background: rgba(235, 233, 241, 1);
}

.badge-tag-ct02 {
  color: rgba(0, 207, 232, 1);
  background: rgba(0, 207, 232, 0.12);
}

.badge-tag-ct03 {
  color: rgba(0, 178, 127, 1);
  background: rgba(186, 234, 220, 1);
}

.badge-tag-ct04 {
  color: rgba(254, 160, 0, 1);
  background: rgba(254, 160, 0, 0.16);
}

.badge-tag-ct05 {
  color: rgba(89, 89, 89, 1);
  background: rgba(202, 253, 0, 1);
}

.badge-tag-ct06 {
  color: rgba(115, 103, 240, 1);
  background: rgba(176, 169, 255, 0.3);
}

.badge-tag-ct07 {
  color: rgba(0, 146, 220, 1);
  background: rgba(252, 197, 0, 0.1);
}

.badge-tag-ct08 {
  color: rgba(101, 91, 150, 1);
  background: rgba(0, 146, 220, 0.24);
}

.badge-tag-ct09 {
  color: rgba(89, 89, 89, 1);
  background: rgba(254, 160, 0, 0.16);
}

.badge-tag-ct10 {
  color: rgba(220, 0, 51, 1);
  background: rgba(220, 0, 51, 0.16);
}

li.select2-results__option:empty {
  display: none !important;
}
